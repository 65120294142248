/**
 *  Client version of Repository/Infrastructure/UserRoles.cs
 *  TODO: Code generation - for now, we will have to manually update both files so that they match
 */
export enum UserRoles {
  System = "System",
  
  Admin = "Admin",
  Admin_Categories_View = "Admin_Categories_View",
  Admin_Categories_Edit = "Admin_Categories_Edit",
  Admin_Clients_View = "Admin_Clients_View",
  Admin_Clients_Edit = "Admin_Clients_Edit",
  Admin_QBCodes_View = "Admin_QBCodes_View",
  Admin_QBCodes_Edit = "Admin_QBCodes_Edit",
  Admin_Team_View = "Admin_Team_View",
  Admin_Team_Edit = "Admin_Team_Edit",
  Admin_SecurityGroups_View = "Admin_SecurityGroups_View",
  Admin_SecurityGroups_Edit = "Admin_SecurityGroups_Edit",
  Admin_Users_View = "Admin_Users_View",
  Admin_Users_Edit = "Admin_Users_Edit",
  Admin_ImpersonateUser = "Admin_ImpersonateUser",

  Project = "Project",
  Project_Projects_View = "Project_Projects_View",
  Project_Projects_Edit = "Project_Projects_Edit",
  Project_ProjectDetails_View = "Project_ProjectDetails_View",
  Project_ProjectDetails_Edit = "Project_ProjectDetails_Edit",

  Billing = "Billing",
  Billing_Summary_View = "Billing_Summary_View",
  Billing_Summary_Edit = "Billing_Summary_Edit",
  Billing_Summary_SendBill = "Billing_Summary_SendBill",
  Billing_Details_View = "Billing_Details_View",
  Billing_Details_Edit = "Billing_Details_Edit",

  Reports = "Reports",
  Reports_Billable_View = "Reports_Billable_View",
  Reports_Billable_Edit = "Reports_Billable_Edit",
  Reports_WriteOffReport_View = "Reports_WriteOffReport_View",
  Reports_WriteOffReport_Edit = "Reports_WriteOffReport_Edit",
  Reports_TimeActivityByClient_View = "Reports_TimeActivityByClient_View",
  Reports_TimeActivityByClient_Edit = "Reports_TimeActivityByClient_Edit",
  Reports_TimeActivityByProject_View = "Reports_TimeActivityByProject_View",
  Reports_TimeActivityByProject_Edit = "Reports_TimeActivityByProject_Edit",
  Reports_TimeActivityByPerson_View = "Reports_TimeActivityByPerson_View",
  Reports_TimeActivityByPerson_Edit = "Reports_TimeActivityByPerson_Edit",

  Dashboards = "Dashboards",
  Dashboards_Dashboard_View = "Dashboards_Dashboard_View",
  Dashboards_Dashboard_Edit = "Dashboards_Dashboard_Edit",

  TimeTracker = "TimeTracker",
  TimeTracker_DayView_View = "TimeTracker_DayView_View",
  TimeTracker_DayView_Edit = "TimeTracker_DayView_Edit",
  TimeTracker_DayView_EditAllUsers = "TimeTracker_DayView_EditAllUsers",
  TimeTracker_WeekView_View = "TimeTracker_WeekView_View",
  TimeTracker_WeekView_Edit = "TimeTracker_WeekView_Edit",
  TimeTracker_PeoplePlanner_View = "TimeTracker_PeoplePlanner_View",
  TimeTracker_PeoplePlanner_Edit = "TimeTracker_PeoplePlanner_Edit",

  TimeTracker_HolidayGroups_View = "TimeTracker_HolidayGroups_View",
  TimeTracker_HolidayGroups_Edit = "TimeTracker_HolidayGroups_Edit",

  Sales = "Sales",
  Sales_Estimate_View = "Sales_Estimate_View",
  Sales_Estimate_Edit = "Sales_Estimate_Edit",

  Stewardship = "Stewardship",
  Stewardship_Manage_View = "Stewardship_Manage_View",
  
  Framework = "Framework",
  Framework_ProofOfConcepts = "Framework_ProofOfConcepts",
}
